import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
  },
  {
    path: "/preview/:id",
    name: "preview",
    component: () =>
      import(/* webpackChunkName: "preview" */ "../views/PreviewView.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      if (to.params.id) {
        next();
      } else {
        next({ name: "home" });
      }
    },
  },
  {
    path: "/edit/:id",
    name: "edit",
    component: () =>
      import(/* webpackChunkName: "editable" */ "../views/EditableView.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      if (to.params.id) {
        next();
      } else {
        next({ name: "home" });
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
