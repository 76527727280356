<template>
  <div id="app">
    <router-view />
  </div>
  <notifications position="bottom center" />
</template>

<script setup>
import { onMounted } from "vue";
import { socket } from "@/socket";
import { useRouter } from "vue-router";
const router = useRouter();

// remove any existing listeners (after a hot module replacement)
socket.off();

onMounted(() => {
  socket.on("reset", () => {
    router.push("/");
  });
});
</script>
